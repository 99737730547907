<template>
  <template v-if="null !== post">
    <Breadcrumbs :breadcrumbs="buildBreadcrumbs(post.data)" />
    <PostBase
      :post="post.data"
      :current-route="{
        routeName: 'blogTest_post',
        params: { postSlug },
      }"
    />
  </template>
</template>

<script setup>
import Breadcrumbs from "~/modules/shared/breadcrumbs/Breadcrumbs.vue";
import { useStrapiApi } from "~/uses/useMyFetch";
import { getPostQuery } from "~/nuxt_modules/blog/utils/query.utils";
import PostBase from "~/nuxt_modules/blog/ui/pages/Post/PostBase.vue";
import { useLanguageStore } from "~/store/languageStore";

onMounted(() => {
  useStrapiApi(`/blog/posts/seen/${postSlug}`, {
    method: "POST",
    server: false,
  });
});

const { postSlug } = useRoute().params;
const { language } = useLanguageStore();

const { data: post } = await useAsyncData(
  async () => {
    return await useStrapiApi(
      `/blog/posts/slug/${postSlug}?${getPostQuery(language.name)}`,
    ).catch(() => {
      throw createError({ statusCode: 404, fatal: true });
    });
  },
  { watch: language, immediate: true, deep: true },
);

const buildBreadcrumbs = (post) => {
  return [
    {
      name: "@Blog",
      routeName: "blogTest_blog",
    },
    {
      name: post.title ?? "Null",
      routeName: "blogTest_post",
      params: {
        postSlug,
      },
    },
  ];
};
</script>
